import _ from 'lodash';
import moment from 'moment';

import { renderNumberCellHTML } from 'common/DataTypeFormatter';
import { ViewColumn } from 'common/types/viewColumn';

import {
  CalculationTypes,
  PeriodSizes,
  PeriodTypes,
  StartDateTypes,
  TargetTypes
} from './constants';

import { shouldUsePercentSign, getPercentScaleMultiplier } from './percents';
import * as ReportingPeriods from './reportingPeriods';
import { Measure } from '../types';
import type BigNumber from 'bignumber.js';

// Returns the date a measure ended at as a momentjs instance.
// Returns null if the measure is not ended.
export const getEndedDate = (measure: Measure) => {
  if (!measure) { throw new Error('measure is required'); }

  const now = moment();
  const configuredEndDate = measure?.metricConfig?.reportingPeriod?.endsBeforeDate;
  const passedEndDate = now.isAfter(configuredEndDate);
  return passedEndDate ? moment(configuredEndDate).subtract(1, 'day') : null;
};

// Checks to see if the given measure has ended.
export const hasMeasureEnded = (measure: Measure) => {
  return !!getEndedDate(measure);
};

// Checks to see if the endsBeforeDate of a measure is valid
// isEndDateValid returns true if:
//   endsBeforeDate is after StartDate
//   endsBeforeDate is null
//   measure is a daily reporting period
export const isEndDateValid = (measure: Measure) => {
  const reportingPeriodConfig = measure.metricConfig?.reportingPeriod;
  const startDateType = measure.metricConfig?.reportingPeriod?.startDateConfig?.type;
  const endsBeforeDate = reportingPeriodConfig?.endsBeforeDate;
  const cumulativeMathStartDate = measure.metricConfig?.arguments?.cumulativeMathStartDate;

  if (startDateType === StartDateTypes.FLOATING) {
    if (cumulativeMathStartDate && endsBeforeDate && moment(endsBeforeDate).isBefore(cumulativeMathStartDate)) {
      return false;
    } else {
      return true;
    }
  } else {
    const date = measure.metricConfig?.reportingPeriod?.startDateConfig?.date;
    return endsBeforeDate ? moment(date).isSameOrBefore(endsBeforeDate) : true;
  }
};

/**
 * @param hasPercentFormat is exposed for testing
 *
 * Returns whether or not to use the column underlying the metric calculation
 * to determine the formatting for the calculation. The underlying column does
 * not factor in for count, and for rate we decided to sidestep the issue of
 * figuring out unit math.
 *
 * NOTE: We re-implement percent column formatting in measures due to the way
 * the visualization library handles scale. Essentially, we need to pass the
 * data and targets in a single scale so that the chart is formatted correctly.
 * This fixes the scenario where the underlying dataset has percentScale: 1 and
 * allows the use to enter targets at regular (100) scale.
 *
 * NOTE: exported for testing
 */
export const shouldUseColumnFormatting = (measure: Measure, calculationColumns: ViewColumn[], hasPercentFormat = shouldUsePercentSign) => {
  const calculationType = _.get(measure, 'metricConfig.type');
  return !_.includes([CalculationTypes.RATE, CalculationTypes.COUNT], calculationType) &&
    !hasPercentFormat(measure.metricConfig!, calculationColumns);
};

export const getColumnFormat = (measure: Measure, calculationColumns: ViewColumn[], options = {}): ViewColumn => {
  const measureNumberFormat = getMeasureNumberFormat(measure);
  const asPercent = shouldUsePercentSign(measure.metricConfig!, calculationColumns);
  // NOTE: This is actually a fake ViewColumn, but it will work for our purposes
  const format = _.merge(
    { format: {
      asPercent,
      forceHumane: true,
      ...measureNumberFormat
    } },
    options) as any as ViewColumn;

  return shouldUseColumnFormatting(measure, calculationColumns) && !_.isEmpty(calculationColumns) ?
    _.merge(_.head(calculationColumns), format) :
    format;
};

/**
 * @param {BigNumber} value
 * @param {object} measure
 * @param {object[]} calculationColumns
 * @param {boolean} scalePercents Whether to use column based percent scaling, used primarily for targets
 */
export const formatMeasureValue = (
  value: BigNumber,
  measure: Measure,
  calculationColumns: ViewColumn[],
  scalePercents = true,
  retainSmallDecimals = true
) => {
  const { metricConfig } = measure;
  const options = shouldUsePercentSign(metricConfig!, calculationColumns) ?
    { precisionStyle: 'percentage', percentScale: '100' } :
    {};

  const columnFormat = getColumnFormat(measure, calculationColumns, { format: options });
  if (scalePercents) {
    // Note: Rate calculations displayed as percents are scaled as part of the measure calculator.
    const calculationType = metricConfig?.type;
    const percentScale = getPercentScaleMultiplier(calculationColumns, calculationType!);
    value = value.times(percentScale);
  }
  return renderNumberCellHTML(value, columnFormat, { retainSmallDecimals });
};

export const formatMeasureResultBigNumber = (
  value: BigNumber,
  measure: Measure,
  calculationColumns: ViewColumn[],
  scalePercents = true,
  retainSmallDecimals = false
) => {
  return formatMeasureValue( value, measure, calculationColumns, scalePercents, retainSmallDecimals);
};

export const hasOverlappingTargets = (measure: Measure) => {
  const targets = getValidTargets(measure);

  // There can't possibly be overlapping targets if there's 1 or less
  if (targets.length <= 1) {
    return false;
  } else if (targets[0].type === TargetTypes.ONGOING) {
    // If there are multiple ongoing targets, then they are overlapping
    return true;
  }

  // Targets are periodic
  const numStartDatesWithMultipleTargets = _(targets).
    groupBy('startDate').
    filter((targetsWithStartDate) => (targetsWithStartDate.length > 1)).
    compact().
    value().
    length;

  return numStartDatesWithMultipleTargets > 0;
};

// Returns all targets that have a value
export function getValidTargets(measure: Measure) {
  const targets = measure.metricConfig?.targets ?? [];
  return targets.filter((t) => t && t.value);
}

/**
 * Whether to allow switching the timeline scope.
 */
export function allowTimelineScopeCurrent(measure: Measure) {
  const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod', {});
  const lastReportingPeriod = ReportingPeriods.isConfigValid(reportingPeriod) ?
    ReportingPeriods.getLastOpenReportingPeriod(reportingPeriod) :
    undefined;
  return (
    shouldShowTimelineScopeConfigOptions(measure) &&
    // there must be at least one reporting period
    !_.isUndefined(lastReportingPeriod)
  );
}

/**
 * Whether timeline scope options are visible for the current measure. They may still
 * be disabled if the reporting period configuration is invalid.
 */
export function shouldShowTimelineScopeConfigOptions(measure: Measure) {
  return (
    _.get(measure, 'metricConfig.reportingPeriod.size') !== PeriodSizes.DAY &&
    _.get(measure, 'metricConfig.reportingPeriod.type') === PeriodTypes.OPEN
  );
}

/**
 * Returns number format configured on the measure. Does not include formatting
 * configured in underlying columns.
 */
export const getMeasureNumberFormat = (measure: Measure) => {
  const decimalPlaces = measure?.metricConfig?.display?.decimalPlaces ?? 2;
  // maxLength was chosen based on looking at roughly how many digits fit into the div.
  // This is an approximate value that could be refined later.
  return { decimalPlaces, maxLength: 6 };
};
